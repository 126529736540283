import React, {useState, useEffect} from 'react';
import { Link } from 'gatsby';
import Layout from "../../components/layout";
import Loader from "../../components/loading";
import { Container, Row, Col, Form, Button, Alert} from 'react-bootstrap';
import Fetch from '../../helpers/fetch';
import constants from '../../helpers/constants';
import LocalStorage from '../../components/localStorage';
import getDecodedToken from '../../helpers/jwtToken';


import { navigate } from 'gatsby';

const ChangePasswordByToken = ({location}) => {
  const [showLoader, setShowLoader] = useState(false)
  const [isTokenStateGood, setIsTokenStateGood] = useState(false);
  const [newPwd, setNewPwd] = useState('');
  const [newPwdConfirm, setNewPwdConfirm] = useState('');
  const [validated, setValidated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoginLink, setShowLoginLink] = useState(false);
  const [token, setToken] = useState(location && location.search ? location.search.split('=')[1] : '')

  const isTokenValid = () => {
    const token = location.search.split('=')[1];
    const url=`${constants.BACKEND_URL}/publicCustomers/validPwdToken`
    const data={
      token
    }
    setShowLoader(true)
    Fetch.post(url, JSON.stringify(data)).then(data => {
     if (data.error) {
       setShowError(true);
       setShowLoginLink(true);
       setErrorMessage(data.error);
       setShowLoader(false)
       return;
     }
     setIsTokenStateGood(true);
     setShowError(false);
     setShowLoader(false)
     setErrorMessage('');
    })
  }

  const saveNewPassword = () => {
    setShowLoader(true)
    const url=`${constants.BACKEND_URL}/publicCustomers/changePassword`
    const data={
      token,
      newPwd,
    }
    Fetch.post(url, JSON.stringify(data)).then(data => {
      if (data.error) {
        setShowLoader(false);
        setShowError(true);
        setErrorMessage(data.error);
        return;
      }
      
      let data1 = JSON.stringify( {username: data.username, password: newPwd})
    
      Fetch.post(`${constants.BACKEND_URL}/users-auth/login`, data1).then((res) => {
        if (res.error) {
          setShowLoader(false);
          setShowError(true);
          setErrorMessage(data.error);
          return;
        }
        if (res && res.access_token) {
          setShowLoader(false);
          LocalStorage.set('jwt', res.access_token);

          if (res.firstLogin) {
            navigate('/profile/changePassword');
            return;
          }
          
          if (getDecodedToken().role === 'user') {
            navigate('/profile', {
              state: {
                passwordChange: true
              }
            })
            return;
          }

        }
      })
    })

    setShowLoader(false)
  }

  useEffect(() => {
    LocalStorage.removeItem('jwt','')
    isTokenValid()
    return () => {
    }
  }, [])

  const handleItemChange = (e) => {
    const {property} = e.target.dataset;
    switch (property) {
      case 'newPwd':
        setNewPwd(e.target.value);
        break;
      case 'newPwdConfirm':
        setNewPwdConfirm(e.target.value);
        break;
      default:
        break;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    const newPwdElement = document.querySelector('#newPwdInput')
    const newPwdConfirmElement = document.querySelector('#newPwdConfirmInput')
    
    
    if (newPwdElement.checkValidity() === false) {
      if (newPwdElement.validity.valueMissing) {
        document.querySelector("#newPwd.invalid-feedback").innerHTML="Please enter your new password"
      } else if (newPwdElement.validity.tooShort){
        document.querySelector("#newPwd.invalid-feedback").innerHTML="Password needs to be 8 characters long"
      } else if (newPwdElement.validity.patternMismatch){
        document.querySelector("#newPwd.invalid-feedback").innerHTML="Password needs to have at least one uppercase character, one lowercase character, one number, and one special character ( !@#$%^&* ) "
      }
    }

    if (newPwdConfirmElement.checkValidity() === false) {
      if (newPwdConfirmElement.validity.valueMissing) {
        document.querySelector("#newPwdConfirm.invalid-feedback").innerHTML="Please confirm your new password"
      } else if (newPwdConfirmElement.validity.tooShort){
        document.querySelector("#newPwdConfirm.invalid-feedback").innerHTML="Password needs to be 8 characters long"
      } else if (newPwdElement.validity.patternMismatch){
        document.querySelector("#newPwdConfirm.invalid-feedback").innerHTML="Password needs to have at least one uppercase character, one lowercase character, one number, and one special character ( !@#$%^&* ) "
      }
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    if (newPwd !== newPwdConfirm) {
      setShowError(true);
      setErrorMessage('Passwords are not identical');
      return;
    }
    setShowError(false);
    setErrorMessage('');
    setValidated(false);
    saveNewPassword();
  };
  
  return (
    <Layout>
      <Container>
          <Loader show={showLoader}></Loader>
          
          {showError && (
            <>
              <Alert dismissible variant="danger" onClose={()=>{setShowError(false)}}>{errorMessage}</Alert>
            </>
          )}
          {showLoginLink && (
            <>
              <Link to={'/login'}>Return to Login</Link>
            </>
          )}
          {isTokenStateGood && (
            <>
              <Alert variant="info">
              Password needs to be 8 characters long<br />
              Password needs to have at least one uppercase character, one lowercase character, one number, and one special character ( !@#$%^&* ) 
              </Alert>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group as={Row} >
                  <Form.Label column sm={4}>
                    New Password
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control 
                      id="newPwdInput"
                      required
                      minLength={8}
                      pattern={`${constants.passwordRegex}`}
                      data-property="newPwd" 
                      type="password" 
                      placeholder="" 
                      value={newPwd} 
                      onChange={handleItemChange}
                    />
                    <Form.Control.Feedback id="newPwd" type="invalid">Password needs to have minimum of 8 chars</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} >
                  <Form.Label column sm={4}>
                    Confirm New Password
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control 
                      id="newPwdConfirmInput"
                      required
                      minLength={8}
                      pattern={`${constants.passwordRegex}`}
                      data-property="newPwdConfirm" 
                      type="password" 
                      placeholder="" 
                      value={newPwdConfirm} 
                      onChange={handleItemChange}
                    />
                    <Form.Control.Feedback id="newPwdConfirm" type="invalid">Please confirm your new password</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <p className="text-info">Note: We will log you in automatically to your profile after successfully changing your password</p>
                <Button className="mr-1 mb-3" variant="outline-primary" type="submit" disabled={()=>{}}>Save</Button>
                <Button className="mr-1 mb-3" variant="danger" onClick={()=>{navigate('/login')}}>Cancel</Button>

              </Form>

            </>
           )
         }
      </Container>
    </Layout>
  )
}

export default ChangePasswordByToken 